import { createRouter, createWebHistory } from 'vue-router';
import MailPage from '@/pages/MailPage.vue';
import EditPage from '@/pages/EditPage.vue';
import TalkPage from '@/pages/TalkPage.vue';
import GoalPage from '@/pages/GoalPage.vue';
import IndexPage from '@/pages/IndexPage.vue';
import UnitsPage from '@/pages/UnitsPage.vue';
import LandingPage from '@/pages/LandingPage.vue';
import ExclusionPage from '@/pages/ExclusionPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: IndexPage
  },
  {
    path: '/cz',
    name: 'HomeLocalize',
    component: IndexPage
  },
  {
    path: '/:locale?/units',
    name: 'Units',
    component: UnitsPage
  },
  {
    path: '/:locale?/exclusion',
    name: 'Exclusion',
    component: ExclusionPage
  },
  {
    path: '/:locale?/goal',
    name: 'Goal',
    component: GoalPage
  },
  {
    path: '/:locale?/talk',
    name: 'Talk',
    component: TalkPage
  },
  {
    path: '/:locale?/edit',
    name: 'Edit',
    component: EditPage
  },
  {
    path: '/:locale?/mail',
    name: 'Mail',
    component: MailPage
  },
  {
    path: '/:locale?/landing',
    name: 'Landing',
    component: LandingPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;