<template>
    <main id="main">
        <router-view></router-view>
    </main>
</template>
  
<script>
export default {
    name: 'App'
}
</script>

<style>
#main {
    width: 100%;
}

* {
    margin: 0;
    box-sizing: border-box;
}

:root {
    --bg: #1F2021;
    --bg-reviews: #0B0B0A;
    --font: #fafafa;
    --active: #365A8D;
    --primary: hsl(14, 84%, 63%);
    --secondary: #2C323B;
    --carbs-color: hsl(45, 97%, 48%);
    --fats-color: hsl(185, 85%, 43.5%);
    --proteins-color: hsl(260, 100%, 73%);
    --color-primary: #107467;
    --box-shadow-light: 0px 0px 14px rgba(0, 0, 0, .2);
    --rate-fill-color: #f7ba2a;
}

html, body {
    width: 100%;
    height: 100%;
    background-color: var(--bg);
}

ul, li {
    padding: 0;
    list-style: none;
}

button, input, select {
    outline: none;
    border: none;
}

svg {
    flex-shrink: 0;
    vertical-align: middle;
}

svg.feather {
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    width: 2rem;
    height: 2rem;
}

h1, h2, h3, h4, h5, p, a, span, div {
    color: var(--font);
}

html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

a, button {
    cursor: pointer;
    text-decoration: none;
}

#app {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    padding: 0 20%;
    overflow-x: hidden;
}

.hidden {
    display: none !important;
}

@media screen and (max-width: 1024px) {
    #app {
        padding: 0 10%;
    }
}

@media screen and (max-width: 564px) {
    #app {
        padding: 0 1rem;
    }
}
</style>