<template>
    <ul class="style-block">
        <li class="style-item">{{ cal }} {{ locale.per_q6[lang] }}</li>
        <li class="style-item"><div class="carbs-decor"></div>{{ locale.least_q6[lang] }} {{ carb }}g {{ locale.carb_q6[lang] }}</li>
        <li class="style-item"><div class="fats-decor"></div>{{ locale.least_q6[lang] }} {{ fat }}g {{ locale.fat_q6[lang] }}</li>
        <li class="style-item"><div class="proteins-decor"></div>{{ locale.least_q6[lang] }} {{ protein }}g {{ locale.protein_q6[lang] }}</li>
    </ul>
</template>

<script>
import data from '@/locale';

export default {
    name: 'StyledInfo',
    props: {
        lang: String,
        cal: Number,
        fat: Number,
        carb: Number,
        protein: Number
    },
    data() {
        return {
            locale: data.data
        }
    }
}
</script>

<style scoped>
.style-block {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.style-item {
    font-size: 1rem;
    font-weight: 300;
    color: var(--font);

    display: inline-flex;
    gap: .3rem;
    align-items: center;
}

.carbs-decor, .fats-decor, .proteins-decor {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: var(--secondary);
}

.carbs-decor {
    background-color: var(--carbs-color);
}

.fats-decor {
    background-color: var(--fats-color);
}

.proteins-decor {
    background-color: var(--proteins-color);
}
</style>
