<template>
    <header class="landing-header">
        <div class="landing-header__info">
            <h5 class="header-info__text">{{ locale.offer_ends[lang] }}</h5>
            <p class="header-info__time">{{ parseInt(time / 60) }}:{{ (time % 60) > 9 ? time % 60 : `0${time % 60}` }}</p>
        </div>
        <a href="#plans" class="landing-header__button">{{ locale.get_plan[lang] }}</a>
    </header>
</template>

<script>
import data from '@/locale';

export default {
    name: 'LandingHeader',
    props: {
        lang: String,
        time: Number
    },
    data() {
        return {
            locale: data.data
        }
    }
}
</script>

<style scoped>
.landing-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;
    background-color: var(--bg);
}

.landing-header__info {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.header-info__text {
    font-size: .85rem;
    font-weight: 700;
    text-transform: uppercase;
}

.header-info__time {
    color: var(--color-primary);
    font-size: 1.45rem;
    font-weight: 700;
}

.landing-header__button {
    background-color: var(--color-primary);
    font-size: 1rem;
    padding: .65rem 1rem;
    border-radius: 12px;
}
</style>