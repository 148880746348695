<template>
    <MyHeader userNick="Anonimus" />
    <MyNavigation :navs="navs" />
    <MyTest :preview="getImageUrl('./q_2.png')" :lang="lang" nextPage="Units" :title="locale.title_q2[this.lang]" :description="locale.description_q2[this.lang]">
      <TestPart :title="locale.common_q2[this.lang]">
        <CheckBoxes :items="items.simple" />
      </TestPart>
      <MySpoiler :title="locale.other_q2[this.lang]">
        <TestPart v-for="(item, index) in items.spoiler" cls="gap-05" :key="index" :title="item.title">
          <CheckBoxes :items="item.items" />
        </TestPart>
      </MySpoiler>
    </MyTest>
</template>

<script>
import data from '@/locale';
import MyTest from '@/components/Test.vue'
import MyHeader from '@/components/Header.vue';
import MyNavigation from '@/components/Navigation.vue';

import MySpoiler from '@/components/Spoiler.vue'; 
import TestPart from '@/components/TestPart.vue';
import CheckBoxes from '@/components/CheckBoxes.vue';

export default {
    name: 'ExclusionPage',
    components: {
      MyTest,
      MyHeader,
      MyNavigation,
      TestPart,
      CheckBoxes,
      MySpoiler
    },
    methods: {
        getImageUrl(path) {
            let images = require.context('@/assets/', false, /\.png$|\.jpg$/);

            return images(path);
        }
    },
    created() {
      this.lang = !this.$route.params.locale || !this.supportedLangs.includes(this.$route.params.locale) ? "en" : this.$route.params.locale

      this.items = { 
          simple: [this.locale.o1_q2[this.lang], this.locale.o2_q2[this.lang], this.locale.o3_q2[this.lang], this.locale.o4_q2[this.lang], this.locale.o5_q2[this.lang], this.locale.o6_q2[this.lang], this.locale.o7_q2[this.lang], this.locale.o8_q2[this.lang]],
          spoiler: [
            {
              title: this.locale.c1_o1_q2[this.lang],
              items: [this.locale.c1_o1_q2[this.lang], this.locale.c1_o2_q2[this.lang], this.locale.c1_o3_q2[this.lang], this.locale.c1_o4_q2[this.lang], this.locale.c1_o5_q2[this.lang], this.locale.c1_o6_q2[this.lang], this.locale.c1_o7_q2[this.lang]]
            },
            {
              title: this.locale.c2_o1_q2[this.lang],
              items: [this.locale.c2_o1_q2[this.lang]]
            },
            {
              title: this.locale.c3_o1_q2[this.lang],
              items: [this.locale.c3_o1_q2[this.lang], this.locale.c3_o2_q2[this.lang], this.locale.c3_o3_q2[this.lang], this.locale.c3_o4_q2[this.lang], this.locale.c3_o5_q2[this.lang], this.locale.c3_o6_q2[this.lang], this.locale.c3_o7_q2[this.lang]]
            },
            {
              title: this.locale.c4_o1_q2[this.lang],
              items: [this.locale.c4_o1_q2[this.lang], this.locale.c4_o2_q2[this.lang], this.locale.c4_o3_q2[this.lang]]
            },
            {
              title: this.locale.c5_o1_q2[this.lang],
              items: [this.locale.c5_o1_q2[this.lang], this.locale.c5_o2_q2[this.lang], this.locale.c5_o3_q2[this.lang], this.locale.c5_o4_q2[this.lang], this.locale.c5_o5_q2[this.lang]]
            },
            {
              title: this.locale.c6_o1_q2[this.lang],
              items: [this.locale.c6_o1_q2[this.lang], this.locale.c6_o2_q2[this.lang], this.locale.c6_o3_q2[this.lang]]
            },
            {
              title: this.locale.c7_o1_q2[this.lang],
              items: [this.locale.c7_o1_q2[this.lang], this.locale.c7_o2_q2[this.lang], this.locale.c7_o3_q2[this.lang], this.locale.c7_o4_q2[this.lang], this.locale.c7_o5_q2[this.lang], this.locale.c7_o6_q2[this.lang]]
            },
            {
              title: this.locale.c8_o1_q2[this.lang],
              items: [this.locale.c8_o1_q2[this.lang]]
            },
            {
              title: this.locale.c9_o1_q2[this.lang],
              items: [this.locale.c9_o1_q2[this.lang]]
            },
            {
              title: this.locale.c10_o1_q2[this.lang],
              items: [this.locale.c10_o1_q2[this.lang], this.locale.c10_o2_q2[this.lang], this.locale.c10_o3_q2[this.lang], this.locale.c10_o4_q2[this.lang], this.locale.c10_o5_q2[this.lang], this.locale.c10_o6_q2[this.lang]]
            },
            {
              title: this.locale.c11_o1_q2[this.lang],
              items: [this.locale.c11_o1_q2[this.lang], this.locale.c11_o2_q2[this.lang], this.locale.c11_o3_q2[this.lang], this.locale.c11_o4_q2[this.lang]]
            },
            {
              title: this.locale.c12_o1_q2[this.lang],
              items: [this.locale.c12_o1_q2[this.lang], this.locale.c12_o2_q2[this.lang], this.locale.c12_o3_q2[this.lang], this.locale.c12_o4_q2[this.lang], this.locale.c12_o5_q2[this.lang], this.locale.c12_o6_q2[this.lang], this.locale.c12_o7_q2[this.lang], this.locale.c12_o8_q2[this.lang], this.locale.c12_o9_q2[this.lang]]
            },
            {
              title: this.locale.c13_o1_q2[this.lang],
              items: [this.locale.c13_o1_q2[this.lang], this.locale.c13_o2_q2[this.lang], this.locale.c13_o3_q2[this.lang], this.locale.c13_o4_q2[this.lang], this.locale.c13_o5_q2[this.lang], this.locale.c13_o6_q2[this.lang], this.locale.c13_o7_q2[this.lang], this.locale.c13_o8_q2[this.lang], this.locale.c13_o9_q2[this.lang], this.locale.c13_o10_q2[this.lang], this.locale.c13_o11_q2[this.lang]]
            },
            {
              title: this.locale.c14_o1_q2[this.lang],
              items: [this.locale.c14_o1_q2[this.lang], this.locale.c14_o2_q2[this.lang], this.locale.c14_o3_q2[this.lang]]
            },
            {
              title: this.locale.c15_o1_q2[this.lang],
              items: [this.locale.c15_o1_q2[this.lang]]
            }
          ]
        }
    },
    data() {
      return {
        locale: data.data,
        lang: "en",
        supportedLangs: ["en", "cz"],
        navs: ["nav-item nav-item-past", "nav-item nav-parrot", "nav-item", "nav-item", "nav-item", "nav-item"],
        items: {
          simple: [],
          spoiler: []
        }
      }
    }
}
</script>

<style>
.gap-05 {
  gap: .5rem !important;
}
</style>
