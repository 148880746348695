<template>
    <div class="item-ratio">
        <div class="item-ratio-circle"></div>
    </div>
</template>

<script>
export default {
    name: 'MyRatio'
}
</script>

<style scoped>
.item-ratio {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 24px;
    height: 24px;
    border: 2px solid var(--secondary);
    border-radius: 32px;
}

.item-ratio-circle {
    display: inline-flex;

    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 32px;
    transition: .5s all ease-in-out;
}

.item-ratio-circle:hover, .item-v1-click .item-ratio-circle {
    background-color: var(--active);
    transition: .5s all ease-in-out;
}
</style>
