<template>
    <ul class="test-checkboxes">
        <li v-for="(item, index) in items" @click="onSelect(index)" :key="index" :class="this.selectedIndex.includes(index) ? 'test-checkbox test-checkbox-active' : 'test-checkbox'">{{ item }}</li>
    </ul>
</template>

<script>
export default {
    name: 'CheckBoxes',
    props: {
        items: Array
    },
    data() {
        return {
            selectedIndex: []
        }
    },
    methods: {
        onSelect(index) {
            if (index < 0) return;

            if (this.selectedIndex.includes(index)) {
                this.selectedIndex = this.selectedIndex.filter(i => i !== index);

                return;
            }

            this.selectedIndex.push(index);
        }
    }
}
</script>

<style scoped>
.test-checkboxes {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .65rem;
}

.test-checkbox {
    font-size: 1rem;
    font-weight: 400;
    padding: .65rem 1rem;

    border: 2px solid var(--active);
    border-radius: 8px;
    cursor: pointer;
    transition: .5s all ease-in-out;
}

.test-checkbox:hover {
    background-color: var(--secondary);
    transition: .5s all ease-in-out;
}

.test-checkbox-active {
    background-color: var(--active);
}
</style>
