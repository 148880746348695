<template>
    <div class="slider-container" :style="`--color: ${color}; --start: ${start}%; --select: ${select}%; --end: ${end}%;`">
        <div class="slider-track slider-select"></div>
        <div class="slider-track slider-start"></div>
        <div class="slider-track slider-end"></div>
        <input type="range" @change="onMinChange" :min="min" :max="max" :value="minV" step="1" class="slider" />
        <input type="range" @change="onMaxChange" :min="min" :max="max" :value="maxV" step="1" class="slider" />
    </div>
</template>

<script>
export default {
    name: 'MySlider',
    props: {
        min: Number,
        max: Number,
        minV: Number,
        maxV: Number,
        color: String
    },
    methods: {
        onMinChange(e) {
            this.minValue = parseInt(e.target.value);

            this.minValue = this.minValue > 0 && (this.minValue < this.maxValue) ? this.minValue : this.$props.min;
            this.$emit('onMin', this.minValue);
        },
        onMaxChange(e) {
            this.maxValue = parseInt(e.target.value);

            this.maxValue = this.maxValue > 0 && (this.minValue < this.maxValue) ? this.maxValue : this.$props.max;
            this.$emit('onMax', this.maxValue);
        },
        calcSize() {
            this.end = 100 - ((this.$props.maxV / this.$props.max) * 100);
            this.start = (this.$props.minV / this.$props.max) * 100;
            this.select = 100 - (this.start + this.end);
        }
    },
    watch: {
        minV: function() {
            this.calcSize();
        },
        maxV: function() {
            this.calcSize();
        }
    },
    created() {
        this.minValue = this.$props.minV ? this.$props.minV : this.$props.min;
        this.maxValue = this.$props.maxV ? this.$props.maxV : this.$props.max;

        this.calcSize();
    },
    data() {
        return {
            minValue: this.minV ? this.minV : 0,
            maxValue: this.maxV ? this.maxV : 0,
            end: 0,
            start: 0,
            select: 0
        }
    }
}
</script>

<style scoped>
.slider, .slider-container {
    position: relative;

    width: 100%;
    height: 1rem;

    display: block;
    border-radius: 2rem;
    background-color: var(--secondary);
    cursor: pointer;
}

.slider {
    position: absolute;
}

.slider, .slider::-webkit-slider-thumb, .slider::-webkit-slider-runnable-track {
    appearance: none;
    -webkit-appearance: none;
}

.slider::-webkit-slider-thumb {
    position: relative;
    height: 2rem;
    width: 2rem;
    background: var(--color);
    border-radius: 50%;
    z-index: 999;
}

.slider-track {
    position: absolute;
    height: 100%;
    background-color: transparent;
    z-index: 9;
}

.slider-select {
    left: var(--start);
    width: var(--select);
    background-color: var(--color);
    border-radius: 2rem;
}

.slider-start {
    left: 0;
    width: var(--start);
}

.slider-end {
    left: calc(var(--start) + var(--select));
    width: var(--end);
}
</style>