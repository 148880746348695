<template>
    <div @click="onClickItem" :class="classes" :data-title="title">
        <MyRatio />

        <div class="item-logo">
            <img :src="img" alt="#" class="item-image">
        </div>

        <div class="item-info">
            <h5 class="item-title">{{ title }}</h5>
            <p class="item-content">{{ description }}</p>
        </div>
    </div>
</template>

<script>
import MyRatio from '@/components/Ratio.vue' ;

export default {
    name: 'ItemRatio',
    components: {
        MyRatio
    },
    props: {
        img: String,
        title: String,
        classes: String,
        description: String
    }
}
</script>

<style scoped>
.item-v1 {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;

    padding: 1rem 2rem;
    border: 2px solid var(--secondary);
    border-radius: 12px;
    cursor: pointer;
    transition: .5s all ease-in-out;
}

.item-v1:hover, .item-v1-click {
    border-color: var(--active);
    transition: .5s all ease-in-out;
}

.item-logo, .item-image {
    width: 4rem;
    height: 4rem;
}

.item-info {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item-title, .item-content {
    width: 100%;
    text-align: left;
    font-size: 1rem;
}

.item-title {
    font-weight: 700;
}

.item-content {
    font-weight: 500;
}

@media screen and (max-width: 1024px) {
    .test-input {
        max-width: 4rem;
    }
}


@media screen and (max-width: 564px) {
    .test-footer {
        gap: 1rem;
    }

    .test-footer__link {
        width: 100%;
    }
}
</style>
