
export default { 
    data: {
        title_q1: {
            en: "What do you like to eat?", 
            cz: "Co rádi jíte?"
        },
        description_q1: {
            en: "Choose from a pre-set diet. You can fine-tune the excluded foods later.", 
            cz: "Vyberte si z přednastavené stravy. Vyloučené potraviny můžete později doladit."
        },
        tiple_q1: {
            en: "Don't see your preferred diet? Choose \"Anything\" and then customize it later.",
            cz: "Nevidíte svou preferovanou stravu? Vyberte možnost „Cokoli“, později ji můžete upravit."
        },
        a1_q1: {
            en: "Anything",
            cz: "Cokoli"
        },
        l1_q1: {
            en: "Excludes: Nothing",
            cz: "Vyloučeno: nic"
        },
        a2_q1: {
            en: "Keto",
            cz: "Keto"
        },
        l2_q1: {
            en: "Excludes: Grains, Legumes, Starchy Vegetables",
            cz: "Vyloučeno: obiloviny, luštěniny, škrobová zelenina"
        },
        a3_q1: {
            en: "Mediterranean",
            cz: "Středomořská"
        },
        l3_q1: {
            en: "Excludes: Red Meat, Fruit juice, Starchy Vegetables",
            cz: "Vyloučeno: červené maso, ovocné šťávy, škrobová zelenina"
        },
        a4_q1: {
            en: "Paleo",
            cz: "Paleo"
        },
        l4_q1: {
            en: "Excludes: Dairy, Grains, Legumes, Soy, Starchy Vegetables",
            cz: "Vyloučeno: mléčné výrobky, obiloviny, luštěniny, sója, škrobová zelenina"
        },
        a5_q1: {
            en: "Vegan",
            cz: "Veganská"
        },
        l5_q1: {
            en: "Excludes: Red Meat, Poultry, Fish, Shellfish, Dairy, Eggs, Mayo, Honey",
            cz: "Vyloučeno: červené maso, drůbež, ryby, měkkýši, mléčné výrobky, vejce, majonéza, med"
        },
        a6_q1: {
            en: "Vegetarian",
            cz: "Vegetariánská"
        },
        l6_q1: {
            en: "Excludes: Red Meat, Poultry, Fish, Shellfish",
            cz: "Vyloučeno: červené maso, drůbež, ryby, měkkýši"
        },
        cbutton: {
            en: "Continue",
            cz: "Pokračovat"
        },
        title_q2: {
            en: "Are there foods you avoid?",
            cz: "Vyhýbáte se některým potravinám?"
        },
        description_q2: {
            en: "This may be due to allergies or any other reason.",
            cz: "Může to být kvůli alergiím nebo z jiných důvodů."
        },
        common_q2: {
            en: "Common Exclusions",
            cz: "Běžně vylučované potraviny"
        },
        o1_q2: {
            en: "Gluten",
            cz: "Lepek"
        },
        o2_q2: {
            en: "Peanuts",
            cz: "Arašídy"
        },
        o3_q2: {
            en: "Eggs",
            cz: "Vejce"
        },
        o4_q2: {
            en: "Fish",
            cz: "Ryby"
        },
        o5_q2: {
            en: "Tree Nuts",
            cz: "Stromové ořechy"
        },
        o6_q2: {
            en: "Dairy",
            cz: "Mléčné výrobky"
        },
        o7_q2: {
            en: "Soy",
            cz: "Sója"
        },
        o8_q2: {
            en: "Shellfish",
            cz: "Měkkýši"
        },
        other_q2: {
            en: "Other Exclusions",
            cz: "Další vylučované potraviny"
        },
        c1_o1_q2: {
            en: "Dairy",
            cz: "Mléčné výrobky"
        },
        c1_o2_q2: {
            en: "Milk",
            cz: "Mléko"
        },
        c1_o3_q2: {
            en: "Cream",
            cz: "Smetana"
        },
        c1_o4_q2: {
            en: "Cheese",
            cz: "Sýr"
        },
        c1_o5_q2: {
            en: "Yogurt",
            cz: "Jogurt"
        },
        c1_o6_q2: {
            en: "Cottage Cheese",
            cz: "Tvaroh"
        },
        c1_o7_q2: {
            en: "Whey Powder",
            cz: "Syrovátkový prášek"
        },
        c2_o1_q2: {
            en: "Eggs",
            cz: "Vejce"
        },
        c3_o1_q2: {
            en: "Grains",
            cz: "Obiloviny"
        },
        c3_o2_q2: {
            en: "Breakfast Cereals",
            cz: "Snídaňové cereálie"
        },
        c3_o3_q2: {
            en: "Pastas",
            cz: "Těstoviny"
        },
        c3_o4_q2: {
            en: "Breads",
            cz: "Pečivo"
        },
        c3_o5_q2: {
            en: "Rice",
            cz: "Rýže"
        },
        c3_o6_q2: {
            en: "Oatmeal",
            cz: "Ovesné vločky"
        },
        c3_o7_q2: {
            en: "Sugar",
            cz: "Cukr"
        },
        c4_o1_q2: {
            en: "Soy",
            cz: "Sója"
        },
        c4_o2_q2: {
            en: "Tofu",
            cz: "Tofu"
        },
        c4_o3_q2: {
            en: "Soy Milk",
            cz: "Sójové mléko"
        },
        c5_o1_q2: {
            en: "Red Meat",
            cz: "Červené maso"
        },
        c5_o2_q2: {
            en: "Beef",
            cz: "Hovězí"
        },
        c5_o3_q2: {
            en: "Pork/Bacon",
            cz: "Vepřové/slanina"
        },
        c5_o4_q2: {
            en: "Lamb",
            cz: "Jehněčí"
        },
        c5_o5_q2: {
            en: "Veal",
            cz: "Telecí"
        },
        c6_o1_q2: {
            en: "Poultry",
            cz: "Drůbež"
        },
        c6_o2_q2: {
            en: "Chicken",
            cz: "Kuře"
        },
        c6_o3_q2: {
            en: "Turkey",
            cz: "Krůta"
        },
        c7_o1_q2: {
            en: "Fish",
            cz: "Ryby"
        },
        c7_o2_q2: {
            en: "Salmon",
            cz: "Losos"
        },
        c7_o3_q2: {
            en: "Tuna",
            cz: "Tuňák"
        },
        c7_o4_q2: {
            en: "Tilapia",
            cz: "Tilapie"
        },
        c7_o5_q2: {
            en: "Sardines",
            cz: "Sardinky"
        },
        c7_o6_q2: {
            en: "Trout & Snapper",
            cz: "Pstruh a chňapal"
        },
        c8_o1_q2: {
            en: "Shellfish",
            cz: "Měkkýši"
        },
        c9_o1_q2: {
            en: "Mayo",
            cz: "Majonéza"
        },
        c10_o1_q2: {
            en: "Fats & Nuts",
            cz: "Tuky a ořechy"
        },
        c10_o2_q2: {
            en: "Avocado",
            cz: "Avokádo"
        },
        c10_o3_q2: {
            en: "Peanuts",
            cz: "Arašídy"
        },
        c10_o4_q2: {
            en: "Almonds",
            cz: "Mandle"
        },
        c10_o5_q2: {
            en: "Walnuts",
            cz: "Vlašské ořechy"
        },
        c10_o6_q2: {
            en: "Pecans",
            cz: "Pekanové ořechy"
        },
        c11_o1_q2: {
            en: "Legumes",
            cz: "Luštěniny"
        },
        c11_o2_q2: {
            en: "Beans",
            cz: "Fazole"
        },
        c11_o3_q2: {
            en: "Lentils",
            cz: "Čočka"
        },
        c11_o4_q2: {
            en: "Peas",
            cz: "Hrách"
        },
        c12_o1_q2: {
            en: "Fruit",
            cz: "Ovoce"
        },
        c12_o2_q2: {
            en: "Apple",
            cz: "Jablko"
        },
        c12_o3_q2: {
            en: "Banana",
            cz: "Banán"
        },
        c12_o4_q2: {
            en: "Grapes",
            cz: "Hrozny"
        },
        c12_o5_q2: {
            en: "Orange",
            cz: "Pomeranč"
        },
        c12_o6_q2: {
            en: "Strawberries",
            cz: "Jahody"
        },
        c12_o7_q2: {
            en: "Raspberries",
            cz: "Maliny"
        },
        c12_o8_q2: {
            en: "Blueberries",
            cz: "Borůvky"
        },
        c12_o9_q2: {
            en: "Fruit juice",
            cz: "Ovocná šťáva"
        },
        c13_o1_q2: {
            en: "Vegetables",
            cz: "Zelenina"
        },
        c13_o2_q2: {
            en: "Artichoke",
            cz: "Artyčok"
        },
        c13_o3_q2: {
            en: "Asparagus",
            cz: "Chřest"
        },
        c13_o4_q2: {
            en: "Beets",
            cz: "Červená řepa"
        },
        c13_o5_q2: {
            en: "Broccoli",
            cz: "Brokolice"
        },
        c13_o6_q2: {
            en: "Carrots",
            cz: "Mrkev"
        },
        c13_o7_q2: {
            en: "Sprouts",
            cz: "Klíčky"
        },
        c13_o8_q2: {
            en: "Celery",
            cz: "Celer"
        },
        c13_o9_q2: {
            en: "Peppers",
            cz: "Papriky"
        },
        c13_o10_q2: {
            en: "Tomato",
            cz: "Rajče"
        },
        c13_o11_q2: {
            en: "Eggplant",
            cz: "Lilek"
        },
        c14_o1_q2: {
            en: "Starchy Vegetables",
            cz: "Škrobová zelenina"
        },
        c14_o2_q2: {
            en: "Potatoes & Yams",
            cz: "Brambory a batáty"
        },
        c14_o3_q2: {
            en: "Corn",
            cz: "Kukuřice"
        },
        c15_o1_q2: {
            en: "Honey",
            cz: "Med"
        },
        title_q3: {
            en: "How do you measure things?",
            cz: "Jakým způsobem věci měříte?"
        },
        description_q3: {
            en: "We will use this to set goals and display amounts in recipes.",
            cz: "Použijeme to pro nastavení cílů a zobrazování množství v receptech."
        },
        units_q3: {
            en: "Preferred units",
            cz: "Preferované jednotky"
        },
        energy_q3: {
            en: "Preferred unit of energy",
            cz: "Preferovaná jednotka energie"
        },
        title_q4: {
            en: "What is your goal?",
            cz: "Jaký je váš cíl?"
        },
        description_q4: {
            en: "This information lets us suggest meals to help you reach your goal.",
            cz: "Tyto informace nám umožňují navrhovat jídla, která vám pomohou dosáhnout vašeho cíle."
        },
        goal_q4: {
            en: "Set a goal",
            cz: "Nastavit cíl"
        },
        m_goal_q4: {
            en: "General Goal",
            cz: "Obecný cíl"
        },
        e_goal_q4: {
            en: "Exact Goal",
            cz: "Přesný cíl"
        },
        want_q4: {
            en: "I want to",
            cz: "Chci"
        },
        lose_q4: {
            en: "Lose fat",
            cz: "Zhubnout"
        },
        maintain_q4: {
            en: "Maintain weight",
            cz: "Udržet váhu"
        },
        muscle_q4: {
            en: "Build muscle",
            cz: "Nabrat svaly"
        },
        w_goal_q4: {
            en: "Your weight goal",
            cz: "Vaše cílová váha"
        },
        w_change_q4: {
            en: "Target weight change",
            cz: "Cílová změna váhy"
        },
        kg_warn_q4: {
            en: "The CDC and health professionals strongly advise against weight changes of more than 0.9 kg a week.",
            cz: "CDC a zdravotní profesionálové rozhodně nedoporučují změny váhy o více než 0,9 kg za týden."
        },
        lbs_warn_q4: {
            en: "The CDC and health professionals strongly advise against weight changes of more than 2 lbs a week.",
            cz: "CDC a zdravotní profesionálové důrazně nedoporučují změny váhy o více než 2 libry za týden."
        },
        week_q4: {
            en: "kg a week",
            cz: "kg za týden"
        },
        week_lbs_q4: {
            en: "lbs a week",
            cz: "libry za týden"
        },
        title_q5: {
            en: "Tell us about yourself",
            cz: "Řekněte nám něco o sobě"
        },
        description_q5: {
            en: "This information lets us estimate your nutrition requirements for each day.",
            cz: "Tyto informace nám umožňují odhadnout vaše denní nutriční potřeby."
        },
        sex_q5: {
            en: "Sex",
            cz: "Pohlaví"
        },
        male_q5: {
            en: "Male",
            cz: "Muž"
        },
        female_q5: {
            en: "Female",
            cz: "Žena"
        },
        binary_q5: {
            en: "Non-Binary",
            cz: "Nebinární"
        },
        height_q5: {
            en: "Height",
            cz: "Výška"
        },
        weight_q5: {
            en: "Weight",
            cz: "Váha"
        },
        age_q5: {
            en: "Age",
            cz: "Věk"
        },
        activity_q5: {
            en: "Activity Level",
            cz: "Úroveň aktivity"
        },
        jobe_q5: {
            en: "Desk jobe, light exercise",
            cz: "Práce v kanceláři, lehké cvičení"
        },
        workout_q5: {
            en: "Lightly active, workout 3-4 times/week",
            cz: "Lehce aktivní, cvičení 3-4 krát týdně"
        },
        daily_q5: {
            en: "Active daily, frequent exercise",
            cz: "Každodenní aktivita, časté cvičení"
        },
        very_q5: {
            en: "Very Athletic",
            cz: "Velmi sportovně založený"
        },
        extrime_q5: {
            en: "Extremely Athletic",
            cz: "Extrémně sportovně založený"
        },
        bodyfat_q5: {
            en: "Bodyfat",
            cz: "Tělesný tuk"
        },
        low_q5: {
            en: "Low",
            cz: "Nízký"
        },
        medium_q5: {
            en: "Medium",
            cz: "Střední"
        },
        high_q5: {
            en: "High",
            cz: "Vysoký"
        },
        title_q6: {
            en: "Set Nutrition Target",
            cz: "Nastavte nutriční cíle"
        },
        description_q6: {
            en: "Take a look at the daily nutrition targets we've estimated for you based on your profile. You can adjust the targets by clicking the edit button or do it later on your meal planner page.",
            cz: "Podívejte se na denní nutriční cíle, které jsme pro vás odhadli na základě vašeho profilu. Cíle můžete upravit kliknutím na tlačítko Upravit nebo to udělat později na stránce plánovače jídel."
        },
        subtitle_q6: {
            en: "My Nutrition Targets",
            cz: "Moje nutriční cíle"
        },
        edit_q6: {
            en: "Edit",
            cz: "Upravit"
        },
        per_q6: {
            en: "Calories per day",
            cz: "kalorií za den"
        },
        least_q6: {
            en: "At least",
            cz: "Alespoň"
        },
        carb_q6: {
            en: "Carbs",
            cz: "Sacharidů"
        },
        fat_q6: {
            en: "Fats",
            cz: "Tuků"
        },
        protein_q6: {
            en: "Proteins",
            cz: "Bílkovin"
        },
        suggest_q6: {
            en: "Suggested Nutrition Targets",
            cz: "Navrhované nutriční cíle"
        },
        sure_q6: {
            en: "Not sure?",
            cz: "Nejste si jistí?"
        },
        view_q6: {
            en: "View Our Suggestions",
            cz: "Zobrazit naše návrhy"
        },
        hint_q6: {
            en: "Title",
            cz: "Název"
        },
        calories_q6: {
            en: "Calories",
            cz: "Kalorie"
        },
        accept_q6: {
            en: "Accept",
            cz: "Přijmout"
        },
        macros_q6: {
            en: "Target Macros",
            cz: "Cílové makroživiny"
        },
        select_q6: {
            en: "Select the range of each macronutrient you want in your diet. A more flexible range will give you much more variety in your meals.",
            cz: "Vyberte rozsah každé makroživiny, kterou chcete ve své stravě mít. Flexibilnější rozsah vám poskytne větší rozmanitost v jídlech."
        },
        micro_q6: {
            en: "Micronutrients",
            cz: "Mikroživiny"
        },
        mf_q6: {
            en: "Minimum Fiber",
            cz: "Minimum vlákniny"
        },
        ld_q6: {
            en: "Limit Daily Sodium",
            cz: "Omezit denní příjem sodíku"
        },
        ms_q6: {
            en: "Maximum Sodium",
            cz: "Maximální příjem sodíku"
        },
        dc_q6: {
            en: "Limit Daily Cholesterol",
            cz: "Omezit denní příjem cholesterolu"
        },
        mc_q6: {
            en: "Maximum Cholesterol",
            cz: "Maximální příjem cholesterolu"
        },
        enter_mail: {
            en: "Enter your email to learn how to create a bio-individual lifestyle",
            cz: "Zadejte svůj e-mail a zjistěte, jak vytvořit bio-individuální životní styl"
        },
        confirm: {
            en: "Confirm",
            cz: "Potvrdit"
        },
        bodyfat: {
            en: "Body Fat",
            cz: "Tělesný tuk"
        },
        offer_ends: {
            en: "This offer ends in",
            cz: "Tato nabídka končí v"
        },
        get_plan: {
            en: "Get my plan",
            cz: "Získejte můj plán"
        },
        result_typical: {
            en: "Results are not typical. Individual results may vary.",
            cz: "Výsledky nejsou typické. Jednotlivé výsledky se mohou lišit."
        },
        choose: {
            en: "Choose your plan",
            cz: "Vyberte si svůj plán"
        },
        per: {
            en: "per day",
            cz: "za den"
        },
        achieve: {
            en: "The best plan to",
            cz: "Nejlepší plán k"
        },
        goal: {
            en: "achieve your goal",
            cz: "dosažení vašeho cíle"
        },
        success: {
            en: "Success stories from our users",
            cz: "Příběhy úspěšných našich uživatelů"
        },
        users_goal: {
            en: "Our users meet their goals",
            cz: "Naši uživatelé plní své cíle"
        },
        review_one: {
            en: "PerfectPlate has been a game-changer for me! I struggled with losing weight for years, trying different diets that always ended in frustration. With PerfectPlate, I finally found a comfortable and sustainable way to shed those extra pounds. The personalized meal plans fit my preferences perfectly, and I haven’t experienced any of the usual cravings or diet fatigue. I’ve lost 15 pounds in three months and feel better than ever!",
            cz: "PerfectPlate byl pro mě zlomem! Léta jsem bojovala s hubnutím a zkoušela různé diety, které ale nakonec skončily vždycky frustrací. S PerfectPlate jsem konečně našla pohodlný a udržitelný způsob, jak se těch nadbytečných kil zbavit. Personalizované plány jídel přesně odpovídají mým preferencím a během diety jsem nezaznamenala žádné obvyklé chutě nebo únavu. Za tři měsíce jsem zhubla 7,5 kilo a cítím se lépe než dřív!"
        },
        review_two: {
            en: "As someone who wanted to gain muscle mass, I always found it challenging to eat the right amount of calories and nutrients. PerfectPlate made it so easy. The service calculated my exact needs and provided a tailored meal plan that supported my workouts. In just two months, I’ve gained 10 pounds of muscle and my energy levels have skyrocketed. PerfectPlate took the guesswork out of my diet and helped me achieve my goals efficiently.",
            cz: "Jelikož jsem chtěl nabrat svalovou hmotu, měl jsem vždy problém jíst správné množství kalorií a živin. PerfectPlate mi to usnadnil. Služba vypočítala mé přesné potřeby a poskytla mi na míru šitý jídelní plán, který podporoval mé tréninky. Během dvou měsíců jsem nabral 5 kilo svalů a hladina energie se mi výrazně zvýšila. PerfectPlate mi pomohl odstranit nejistotu ohledně stravování a efektivně dosáhnout svých cílů."
        },
        review_three: {
            en: "Maintaining my ideal weight has always been a balancing act. Thanks to PerfectPlate, I now have a reliable partner in my health journey. The personalized nutrition plans keep me on track, ensuring I get the right balance of calories and nutrients. I love how the meal plans are customized to my tastes and dietary restrictions. It’s been six months, and I’ve effortlessly kept my weight stable while enjoying delicious meals every day. PerfectPlate is my go-to for staying fit and healthy!",
            cz: "Udržení si ideální váhy pro mě bylo vždy na hraně. Díky PerfectPlate mám nyní na své cestě ke zdraví spolehlivého partnera. Personalizované plány výživy mi pomáhají jít tím správným směrem a starají se o to, abych měla správnou rovnováhu kalorií a živin. Vyhovuje mi, že jsou plány jídel přizpůsobeny mým chutím a dietním omezením. Je to už šest měsíců, co si bez námahy udržuji stabilní váhu a zároveň si každý den vychutnávám vynikající jídla. PerfectPlate je můj věrný partner pro udržení kondice a zdraví!"
        },
        popular: {
            en: "Most popular",
            cz: "Nejoblíbenější"
        },
        your_weight: {
            en: "Your Weight",
            cz: "Vaše váha"
        },
        g: {
            en: "Goal",
            cz: "Cíl"
        },
        oops: {
            en: "Oops, it looks like our servers are currently overloaded. We apologize for the inconvenience. Please try again later.",
            cz: "Jejda, zdá se, že naše servery jsou momentálně přetížené. Omlouváme se za nepříjemnosti. Zkuste to znovu později."
        }
    }
}
