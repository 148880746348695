<template>
    <nav class="nav">
        <div v-for="(nav, index) in navs" :class="nav" :key="index"></div>
    </nav>
</template>

<script>
export default {
    name: 'MyNavigation',
    props: {
        navs: Array
    }
}
</script>

<style scoped>
.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    overflow: hidden;
}

.nav-item {
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--primary);
    border-radius: 1rem;
}

.nav-item-past {
    cursor: pointer;
    background-color: var(--primary);
}

.nav-parrot {
    width: 3rem !important;
    height: 3rem !important;

    border: none !important;
    border-radius: 0px !important;
    background-image: url('@/assets/navigation_bar.png') !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 564px) {
    .nav {
        gap: 1rem;
    }
}
</style>
