<template>
    <div class="landing-offer" id="plans">
        <div class="landing-plan">
            <h5 class="landing-plan__tag">{{ locale.your_weight[lang] }}</h5>
            <img :src="getImageUrl(`./${sex == 0 ? 'man' : 'women'}_${bodyfat == 0 ? 'skinny' : 'fat'}.png`)" alt="#" class="landing-plan__cover">
            <div class="landing-bar">
                <h5 class="landing-bar__title">{{ locale.bodyfat[lang] }}</h5>
                <ul class="landing-level">
                    <li class="landing-level__part active"></li>
                    <li :class="bodyfat > 0 ? 'landing-level__part active' : 'landing-level__part'"></li>
                    <li :class="bodyfat > 0 ? 'landing-level__part active' : 'landing-level__part'"></li>
                    <li :class="bodyfat > 1 ? 'landing-level__part active' : 'landing-level__part'"></li>
                    <li :class="bodyfat > 1 ? 'landing-level__part active' : 'landing-level__part'"></li>
                </ul>
            </div>
        </div>
        <div class="landing-decor">
            <div class="landing-arrow"></div>
            <div class="landing-arrow r5"></div>
        </div>
        <div class="landing-plan">
            <h5 class="landing-plan__tag">{{ locale.g[lang] }}</h5>
            <img :src="getImageUrl(`./${sex == 0 ? 'man' : 'women'}_sport.png`)" alt="#" class="landing-plan__cover">
            <div class="landing-bar">
                <h5 class="landing-bar__title">{{ locale.bodyfat[lang] }}</h5>
                <ul class="landing-level">
                    <li class="landing-level__part active"></li>
                    <li class="landing-level__part"></li>
                    <li class="landing-level__part"></li>
                    <li class="landing-level__part"></li>
                    <li class="landing-level__part"></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="landing-desc">
        <span>{{ locale.result_typical[lang] }}</span>
    </div>
</template>

<script>
import data from '@/locale';

export default {
    name: 'LandingPlan',
    props: {
        lang: String
    },
    methods: {
        getImageUrl(path) {
            let images = require.context('@/assets/', false, /\.png$|\.jpg$/);

            return images(path);
        }
    },
    created() {
        this.sex = localStorage.getItem("sex") || 0
        this.bodyfat = localStorage.getItem("bodyfat") || 0
    },
    data() {
        return {
            sex: 0,
            bodyfat: 0,
            locale: data.data
        }
    }
}
</script>

<style scoped>
.landing-offer {
    position: relative;
    width: 100%;
    padding: 1rem;
    background-color: #faf0e2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    margin-top: 8rem;
}

.landing-plan {
    position: relative;
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: 1px solid var(--font);
}

.landing-plan:last-child {
    border-right: none !important;
}

.landing-plan__tag {
    color: var(--bg);
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
}

.landing-plan__cover {
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 999;
}

.landing-bar {
    gap: 1rem;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.landing-level {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing-level__part {
    width: 19%;
    height: .65rem;
    background-color: var(--font);
    border-radius: 1rem;
    overflow: hidden;
}

.landing-bar__title {
    color: var(--bg);
    font-size: 1rem;
    font-weight: 700;
}

.active {
    background-color: var(--color-primary);
}

.landing-decor {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    padding: 1rem;
    background-color: #faf0e2;
}

.r5 {
    right: 3vw;
}

.landing-arrow {
    position: relative;
    border-top: 1.5rem solid var(--font);
    border-right: 1.5rem solid var(--font);
    transform: rotate(45deg);
    z-index: 99;
    width: 7vw;
    height: 7vw;
}

.landing-desc {
    width: 100%;
    padding: .85rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-desc span {
    width: 100%;
    text-align: center;
    color: #789;
    font-size: .85rem;
    font-weight: 300;
}

@media screen and (max-width: 768px) {
    .r5 {
        right: 7vw;
    }

    .landing-arrow {
        width: 20vw;
        height: 20vw;
    }

    .landing-plan__cover {
        width: 75%;
        height: 75%;
    }
}
</style>
