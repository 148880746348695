<template>
    <footer class="landing-footer">
        <div class="landing-footer__bg"></div>
        <img src="@/assets/money_back.png" alt="#" class="landing-footer__logo">
        <div class="landing-footer__content">
            <h2 class="footer-content__title">Money-Back Guarantee</h2>
            <p class="footer-content__text">In case you don’t get visible results, you can get a full refund within 30 days after purchase.</p>
        </div>
        <p class="footer-copy">
Copyright ® 2024 ShapeShifter Limited
All Rights Reserved
        </p>
    </footer>
</template>

<script>
export default {
    name: 'LandingFooter'
}
</script>

<style scoped>
.landing-footer {
    position: relative;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: calc(10vh + 2rem);
    padding: 1rem;
}

.landing-footer__bg {
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: rgb(229, 246, 244);
    z-index: 1;
}

.landing-footer__logo {
    position: absolute;
    top: -7.5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 20vh;
    height: 20vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 99;
}

.landing-footer__content {
    gap: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 12.5vh;
    z-index: 99;
}

.footer-content__title {
    width: 100%;
    text-align: center;
    color: var(--bg);
    font-size: 2rem;
    font-weight: 700;
}

.footer-content__text {
    width: 100%;
    text-align: center;
    color: var(--bg);
    font-size: 1rem;
}

.footer-copy {
    width: 100%;
    text-align: center;
    color: var(--secondary);
    font-size: .85rem;
    font-weight: 300;
    text-wrap: wrap;
    z-index: 99;
}
</style>
