<template>
    <div class="test-form">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'TestForm'
}
</script>

<style scoped>
.test-form {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
