<template>
    <div class="suggest">
        <p class="suggest-text">{{ locale.sure_q6[lang] }}</p>
        <button @click="toggleSuggest" class="suggest-button">{{ locale.view_q6[lang] }}</button>
    </div>
    <div class="suggest-container" v-if="showSuggest">
        <div class="suggest-popup">
            <header class="suggest-popup__header">
                <h2 class="suggest-popup__title">{{ locale.suggest_q6[lang] }}</h2>
            </header>
            <StyledInfo :lang="lang" :cal="cal" :fat="fat" :carb="carb" :protein="protein" />
            <footer class="suggest-footer">
                <button @click="toggleSuggest" class="suggest-footer__button">{{ locale.accept_q6[lang] }}</button>
            </footer>
        </div>
    </div>
</template>

<script>
import data from '@/locale';
import StyledInfo from './StyledInfo.vue';

export default {
    name: 'MySuggest',
    components: {
        StyledInfo
    },
    props: {
        lang: String,
        cal: Number,
        fat: Number,
        carb: Number,
        protein: Number
    },
    methods: {
        toggleSuggest() {
            this.showSuggest = !this.showSuggest;
        }
    },
    data() {
        return {
            locale: data.data,
            showSuggest: false
        }
    }
}
</script>

<style scoped>
.suggest {
    width: 100%;
    padding: .65rem 1rem;
    border-radius: 12px;
    background-color: var(--secondary);

    display: flex;
    gap: .3rem;
    align-items: center;
}

.suggest-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .35);
    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;
}

.suggest-footer {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.suggest-popup {
    position: relative;

    display: flex;
    gap: 2rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    padding: 2rem;
    border-radius: 1rem;
    background-color: var(--secondary);
}

.suggest-footer__button {
    width: 100%;
    text-align: center;

    padding: 1rem;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    color: var(--font);
    background-color: var(--active)
}

.suggest-text {
    color: var(--font);
    font-size: 1rem;
    font-weight: 500;

    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.suggest-button {
    color: var(--active);
    font-size: 1rem;
    font-weight: 500;
    padding: .45rem .65rem;
    border-radius: 2rem;
    background-color: transparent;
    transition: .5s ease-in-out;
}

.suggest-button:hover {
    background-color: var(--font);
    transition: .5s ease-in-out;
}

@media screen and (max-width: 1024px) {
    .suggest-popup {
        width: 100vw;
        height: 100vh;

        padding: 2rem 1rem;
        border-radius: 0;
        justify-content: flex-start;
    }
}
</style>
