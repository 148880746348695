<template>
    <section class="test">
        <header class="test-header" v-if="preview">
            <div class="test-header__info">
                <h2 class="test-header__title">{{ title }}</h2>
                <h5 class="test-header__subtitle">{{ description }}</h5>
            </div>
            <div class="test-header__logo">
                <img :src="preview" alt="#" width="128" height="128" class="test-logo__image">
            </div>
        </header>

        <header class="test-header" v-else>
            <div class="test-header__info">
                <h2 class="test-header__title">{{ title }}</h2>
                <h5 class="test-header__subtitle">{{ description }}</h5>
            </div>
        </header>

        <slot></slot>

        <footer class="test-footer">
            <div v-if="tiple != ''" class="test-footer__info">{{ tiple }}</div>
            <div class="test-footer__form">
                <button id="next" class="test-footer__link" @click="onClickNext">
                    <span class="icon svelte-1m78l37">
                        <svg viewBox="3 4 16 16" class="feather">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </span>

                    <span class="text">{{ locale.cbutton[lang] || "Continue" }}</span>
                </button>
            </div>
        </footer>
    </section>
</template>

<script>
import data from '@/locale'; 

export default {
    name: 'MyTest',
    props: {
        lang: String,
        tiple: String,
        preview: String,
        title: String,
        description: String,
        nextPage: String
    },
    methods: {
        onClickNext() {
            this.$router.push({name: this.nextPage, params: {locale: this.$props.lang}});
            
            this.$emit("onNext");
        }
    },
    data() {
        return {
            locale: data.data
        }
    }
}
</script>

<style scoped>
.test {
    width: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-direction: column;
}

.test-header {
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 6fr 1fr;
    padding: 1rem 0;
}

.test-header__info {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.test-header__title {
    font-size: 2rem;
    font-weight: 700;
}

.test-header__subtitle {
    font-size: 1rem;
    font-weight: 500;
}

.test-header__logo, .test-logo__image, .item-logo, .item-image {
    background-repeat: no-repeat;
    background-position: center;

    width: 8rem;
    height: 8rem;
}

.test-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 1rem 0;
}

.test-footer {
    width: 100%;
    
    display: flex;
    padding: 1rem 0;
    padding-bottom: 2rem;
    gap: 1rem;
    flex-direction: column;
}

.test-footer__info {
    width: 100%;
    text-align: left;

    font-size: 1rem;
    font-weight: 300;
}

.test-footer__form {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.test-footer__link {
    align-items: center;
    color: var(--font);
    background-color: var(--primary);
    border-radius: 2rem;
    box-sizing: border-box;

    display: inline-flex;
    gap: .3em;
    justify-content: center;
    padding: 1rem 2rem;
    font-size: 1rem;
    position: relative;
    white-space: nowrap;
}

.icon, .icon svg {
    fill: var(--font);
    width: 1rem;
    height: 1rem;

    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.test-title {
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
}

@media screen and (max-width: 1024px) {
    .test-input {
        max-width: 4rem;
    }
}

@media screen and (max-width: 564px) {
    .test-footer {
        gap: 1rem;
    }

    .test-footer__link {
        width: 100%;
    }
}
</style>
