<template>
    <div :class="isSelected ? 'switcher switcher-active' : 'switcher'" @click="onSwitch">
        <div class="switcher-click">
            <svg viewBox="0 0 24 24" class="feather">
                <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MySwitcher',
    methods: {
        onSwitch() {
            this.isSelected = !this.isSelected;

            this.$emit('onSwitch', this.isSelected);
        }
    },
    data() {
        return {
            isSelected: false
        }
    }
}
</script>

<style scoped>
.switcher {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 10%;
    padding: .45rem;
    border-radius: 2rem;
    border: 1px solid var(--active);
    cursor: pointer;
}

.switcher-click {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 2rem;
    background-color: var(--active);
    border-radius: 2rem;
    overflow: hidden;
    cursor: pointer;
    transition: .5s ease-in-out;
}

.switcher-click svg {
    width: 1rem;
    height: 1rem;
    opacity: 0;
    transition: .5s ease-in-out;
}

.switcher-active svg {
    opacity: 1 !important;
}

.switcher-active .switcher-click {
    transform: translateX(100%);
    transition: .5s ease-in-out;
}

.switcher-active {
    background-color: var(--secondary) !important;
}

@media screen and (max-width: 564px) { 
    .switcher {
        width: 30%;
    }
}
</style>