<template>
    <MyHeader userNick="Anonimus" />
    <MyNavigation :navs="navs" />
    <MyTest :preview="getImageUrl('./q_3.png')" :lang="lang" @onNext="onNext" nextPage="Goal" :title="locale.title_q3[this.lang]" :description="locale.description_q3[this.lang]">
        <TestForm>
            <TestRow :title="locale.units_q3[this.lang]">
                <MyOption :items="items" @onSelect="onMetric" />
            </TestRow>
            <TestRow :title="locale.energy_q3[this.lang]">
                <MyOption :items="items_energy" @onSelect="onEnergy" />
            </TestRow>
        </TestForm>
    </MyTest>
</template>

<script>
import data from '@/locale';
import MyTest from '@/components/Test.vue'
import MyHeader from '@/components/Header.vue';
import MyNavigation from '@/components/Navigation.vue';

import TestRow from '@/components/TestRow.vue';
import TestForm from '@/components/TestForm.vue';
import MyOption from '@/components/Option.vue';


export default {
    name: 'UnitsPage',
    components: {
      MyTest,
      MyHeader,
      MyNavigation,
      TestRow,
      TestForm,
      MyOption
    },
    methods: {
        getImageUrl(path) {
            let images = require.context('@/assets/', false, /\.png$|\.jpg$/);

            return images(path);
        },
        onMetric(sel) {
            this.selectedMetric = sel;
        },
        onEnergy(sel) {
            this.selectedEnergy = sel;
        },
        onNext() {
            localStorage.setItem('metric', this.selectedMetric);
            localStorage.setItem('energy', this.selectedEnergy);
        }
    },
    created() {
        this.lang = !this.$route.params.locale || !this.supportedLangs.includes(this.$route.params.locale) ? "en" : this.$route.params.locale
    },
    data() {
      return {
        locale: data.data,
        lang: "en",
        supportedLangs: ["en", "cz"],
        navs: ["nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-parrot", "nav-item", "nav-item", "nav-item"],
        items: ["U.S. Standard", "Metric"],
        items_energy: ["Calories", "Kilojoules"],
        selectedMetric: 0,
        selectedEnergy: 0
      }
    }
}
</script>
