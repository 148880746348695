<template>
    <h2 class="test-title spoiler-btn" @click="onSpoiler">{{ title }}
        <svg viewBox="0 0 24 24" class="feather">
            <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
    </h2>
    <div :class="isActive ? 'test-spoiler' : 'hidden'">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'MySpoiler',
    props: {
        title: String
    },
    methods: {
        onSpoiler() {
            this.isActive = !this.isActive;
        }
    },
    data() {
        return {
            isActive: false
        }
    }
}
</script>

<style scoped>
.test-spoiler {
    width: 100%;

    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.spoiler-btn {
    width: 100%;
    text-align: left;
    display: inline-flex;
    gap: .3rem;
    align-items: center;
    justify-content: flex-start;
    transition: .5s all ease-in-out;
    cursor: pointer;
}

.spoiler-btn:hover {
    text-decoration: underline;
    transition: .5s all ease-in-out;
}
</style>
