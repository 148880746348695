<template>
    <div class="test-row" :style="style">
        <h5 class="test-row__name">{{ title }}</h5>
        <slot></slot>
    </div>
    <div class="notify" v-if="notify">
        <p class="notify-text">{{ notify }}</p>
    </div>
</template>

<script>
export default {
    name: 'TestRow',
    props: {
        style: String,
        title: String,
        notify: String
    }
}
</script>

<style scoped>
.test-row, .notify {
    width: 100%;
    padding: 1rem 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--secondary);
}

.test-row:last-child {
    border-bottom: none;
}

.test-row__name {
    font-size: 1rem;
    font-weight: 700;
}

.notify {
    border: none;
    border-radius: 12px;

    background-color: hsl(14, 85%, 96%),
}

.notify-text {
    color: hsl(14, 100%, 36%);
    width: 100%;
    text-align: left;
    font-size: 1rem;
}
</style>
