<template>
    <header class="header">
        <div class="header-logo">
            <img src="@/assets/logo.svg" width="96" height="96" alt="#" class="header-logo__img" />
        </div>
    </header>
</template>

<script>
export default {
    name: 'MyHeader',
    props: {
        userNick: String
    }
}
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 1rem 0;
}

.header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-user {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.header-user__img, .header-logo__img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.header-user__img {
    border-radius: 8px;
}

.header-user__title {
    font-size: 1rem;
    font-weight: 500;
}
</style>
