<template>
    <LandingHeader :time="time" :lang="lang" />
    <LandingPlan :lang="lang" />
    <LandingChoice :time="time" :lang="lang" :plans="plans" />
    <LandingReviews :lang="lang" />
    <LandingGoals :lang="lang" />
    <LandingChoice :time="time" :lang="lang" :plans="plans" />
    <LandingFooter />
</template>

<script>
import data from '@/locale';
import LandingPlan from '@/components/LandingPlan.vue';
import LandingGoals from '@/components/LandingGoals.vue';
import LandingHeader from '@/components/LandingHeader.vue';
import LandingChoice from '@/components/LandingChoice.vue';
import LandingFooter from '@/components/LandingFooter.vue';
import LandingReviews from '@/components/LandingReviews.vue';

export default {
    name: 'LandingPage',
    components: {
        LandingPlan,
        LandingGoals,
        LandingHeader,
        LandingChoice,
        LandingFooter,
        LandingReviews
    },
    created() {
        this.lang = !this.$route.params.locale || !this.supportedLangs.includes(this.$route.params.locale) ? "en" : this.$route.params.locale

        setInterval(() => {
            this.time = this.time > 0 ? this.time - 1 : 0
        }, 1000)
    },
    data() {
        return {
            time: 600,
            locale: data.data,
            lang: "en",
            supportedLangs: ["en", "cz"],
            plans: {
                en: [
                    {
                        name: "1-WEEK",
                        sale: "14,99$",
                        price: "10,99$",
                        price_per: "1,57$",
                        is_popular: false
                    },
                    {
                        name: "4-WEEK",
                        sale: "39,99$",
                        price: "15,99$",
                        price_per: "0,57$",
                        is_popular: true
                    },
                    {
                        name: "12-WEEK",
                        sale: "69,99$",
                        price: "25,99$",
                        price_per: "0,3$",
                        is_popular: false
                    }
                ],
                cz: [
                    {
                        name: "1-WEEK",
                        sale: "349,99 Kč",
                        price: "269,99 Kč",
                        price_per: "38,6 Kč",
                        is_popular: false
                    },
                    {
                        name: "4-WEEK",
                        sale: "939,99 Kč",
                        price: "379,99 Kč",
                        price_per: "13,6 Kč",
                        is_popular: true
                    },
                    {
                        name: "12-WEEK",
                        sale: "1’699 Kč",
                        price: "619,99 Kč",
                        price_per: "7,4 Kč",
                        is_popular: false
                    }
                ]
            }
        }
    }
}
</script>

<style>
</style>