<template>
    <div class="landing-container">
        <h2 class="landing-title">{{ locale.users_goal[lang] }}</h2>
        <div class="landing-goals">
            <div class="landing-goal t1">
                <img src="@/assets/mary_after.png" alt="#" class="landing-goal__img" />
            </div>
            <div class="landing-goal t2">
                <img src="@/assets/mary_before.png" alt="#" class="landing-goal__img" />
            </div>
        </div>
        <div class="landing-desc">
            <span>{{ locale.result_typical[lang] }}</span>
        </div>
    </div>
</template>

<script>
import data from '@/locale';

export default {
    name: 'LandingGoals',
    props: {
        lang: String
    },
    data() {
        return {
            locale: data.data
        }
    }
}
</script>

<style scoped>
.landing-container {
    width: 100%;
    overflow: hidden;
}

.landing-title {
    width: 100%;
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

.landing-goals {
    gap: 2rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-goal {
    position: relative;
    width: 40%;
    height: 50vh;
    border-radius: 1rem;
    border: 1rem solid var(--secondary);
    overflow: hidden;
    box-shadow: var(--box-shadow-light);
}

.landing-goal__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.landing-desc {
    width: 100%;
    padding: .85rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-desc span {
    width: 100%;
    text-align: center;
    color: #789;
    font-size: .85rem;
    font-weight: 300;
}

.t1 {
    transform: rotate(-5deg);
}

.t2 {
    transform: rotate(5deg);
}

@media screen and (max-width: 768px) {
    .landing-goal {
        height: 25vh;
    }
}
</style>
