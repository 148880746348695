<template>
    <MyHeader userNick="Anonimus" />
    <MyNavigation :navs="navs" />
    <MyTest :preview="getImageUrl('./q_5.png')" :lang="lang" @onNext="onNext" :title="locale.title_q5[this.lang]" :description="locale.description_q5[this.lang]">
        <TestForm>
            <TestRow :title="locale.sex_q5[this.lang]">
                <MyOption :items="items" @onSelect="onSex" />
            </TestRow>
            <TestRow :title="locale.height_q5[this.lang]">
                <div class="flex">
                    <MyInput v-if="metric != 0" @onChange="onHeight" :value="cm" :error="isError" type="number" addon="cm" />
                    <MyInput v-if="metric == 0" @onChange="onHeightFt" :error="isError" type="number" addon="ft" />
                    <MyInput v-if="metric == 0" @onChange="onHeightInc" :error="isError" type="number" addon="in" />
                </div>
            </TestRow>
            <TestRow :title="locale.weight_q5[this.lang]">
                <MyInput v-if="metric != 0" @onChange="onWeight" :value="kg" :error="isError" type="number" addon="kg" />
                <MyInput v-if="metric == 0" @onChange="onWeightLbs" :error="isError" type="number" addon="lbs" />
            </TestRow>
            <TestRow :title="locale.age_q5[this.lang]">
                <MyInput @onChange="onAge" :value="age" :error="isError" addon="years" />
            </TestRow>
            <TestRow :title="locale.bodyfat_q5[this.lang]">
                <MyOption :items="items_body" @onSelect="onBodyFat" />
            </TestRow>
            <TestRow :title="locale.activity_q5[this.lang]">
                <MyOption :items="items_activity" @onSelect="onActivity" isOld="true" />
            </TestRow>
        </TestForm>
    </MyTest>
</template>

<script>
import data from '@/locale';
import MyTest from '@/components/Test.vue'
import MyHeader from '@/components/Header.vue';
import MyNavigation from '@/components/Navigation.vue';

import TestRow from '@/components/TestRow.vue';
import TestForm from '@/components/TestForm.vue';
import MyOption from '@/components/Option.vue';
import MyInput from '@/components/Input.vue';

export default {
    name: 'TalkPage',
    components: {
      MyTest,
      MyHeader,
      MyNavigation,
      TestRow,
      TestForm,
      MyOption,
      MyInput
    },
    methods: {
        getImageUrl(path) {
            let images = require.context('@/assets/', false, /\.png$|\.jpg$/);

            return images(path);
        },
        onNext() {
            if (!this.cm || !this.kg || !this.age) {
                this.isError = true;

                return;
            }

            this.$router.push({name: "Edit", params: {locale: this.lang}});
        },
        loadData() {
            this.cm = localStorage.getItem('cm') ? localStorage.getItem('cm') : 0;
            this.kg = localStorage.getItem('kg') ? localStorage.getItem('kg') : 0;
            this.age = localStorage.getItem('age') ? localStorage.getItem('age') : 0;
            this.metric = localStorage.getItem('metric');

            if (!this.metric) this.metric = 0;
        },
        onHeight(val) {
            this.cm = parseInt(val);

            localStorage.setItem('cm', this.cm);
        },
        onHeightFt(val) {
            val = parseFloat(val) * 30.48;

            this.onHeight(val);
        },
        onHeightInc(val) {
            val = this.cm + (parseFloat(val) * 2.54);

            this.onHeight(val);
        },
        onWeight(val) {
            this.kg = parseInt(val);

            localStorage.setItem('kg', this.kg);
        },
        onWeightLbs(val) {
            val = parseFloat(val) * 0.454;

            this.onWeight(val)
        },
        onAge(val) {
            this.age = parseInt(val);

            localStorage.setItem('age', this.age);
        },
        onBodyFat(item) {
            localStorage.setItem('bodyfat', item);
        },
        onSex(sel) {
            localStorage.setItem('sex', sel);
        },
        onActivity(sel) {
            localStorage.setItem('activity', sel);
        },
    },
    created() {
        this.lang = !this.$route.params.locale || !this.supportedLangs.includes(this.$route.params.locale) ? "en" : this.$route.params.locale

        this.items = [this.locale.male_q5[this.lang], this.locale.female_q5[this.lang], this.locale.binary_q5[this.lang]]
        this.items_body = [this.locale.low_q5[this.lang], this.locale.medium_q5[this.lang], this.locale.high_q5[this.lang]]
        this.items_activity = [this.locale.jobe_q5[this.lang], this.locale.workout_q5[this.lang], this.locale.daily_q5[this.lang], this.locale.very_q5[this.lang], this.locale.extrime_q5[this.lang]]

        this.loadData();
    },
    data() {
      return {
        locale: data.data,
        lang: "en",
        supportedLangs: ["en", "cz"],
        isError: false,
        metric: 0,
        cm: 0,
        kg: 0,
        age: 0,
        navs: ["nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-parrot", "nav-item"],
        items: [],
        items_body: [],
        items_activity: []
      }
    }
}
</script>

<style>
.flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .3rem;
}
</style>
